<template>
  <TrustContent @redirect-to-page="goToStartTrust"/>
</template>

<script>
import TrustContent from '@innerworks_ds/innerworks-storybook-main/src/stories/TrustPage/TrustContent.vue';

export default {
  name: 'TrustMain',
  components: { TrustContent },
  methods: {
    goToStartTrust() {
      this.$router.push('trust/explain');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
